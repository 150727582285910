<template>
  <div class="ud-body">
    <!-- 搜索表单 -->
    <a-card :bordered="true">
      <a-form
        :model="where"
        :label-col="{ md: { span: 8 }, sm: { span: 24 } }"
        :wrapper-col="{ md: { span: 16 }, sm: { span: 24 } }"
      >
        <a-row>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="电池编号:">
              <a-input
                v-model:value.trim="where.batteryCode"
                placeholder="请输入电池编号"
                allow-clear
              />
            </a-form-item>
          </a-col>          
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="设备编号:">
              <a-input
                v-model:value.trim="where.batteryImei"
                placeholder="请输入设备编号"
                allow-clear
              />
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item class="ud-text-right" :wrapper-col="{ span: 24 }">
              <a-space>
                <a-button type="primary" @click="reload">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <!-- 表格 -->
      <ud-pro-table
        ref="table"
        row-key="batteryId"
        :datasource="datasource"
        :columns="columns"
        v-model:selection="selection"
        :scroll="{ x: 'max-content' }"
      >
        <!-- <template #pointName="{ record }">
          <router-link :to="'/zulin/pointLocation/index?id=' + record.pointId">
            {{ record.pointName }}
          </router-link>
        </template> -->
        <template #protocolType="{ record }">
          <span>
            {{ record.protocolType }}
          </span>
        </template>
        <template #toolbar>          
        </template>
        <template #enable="{ text, record }">
          <a-switch
            :checked="text === 0"
            @change="(checked) => editEnabled(checked, record)"
          />
        </template>
        <template #action="{ record }">
          <a-space>
            <a @click="openDetail(record)">详情</a>
            <a @click="openEdit(record)">修改</a>           
          </a-space>
        </template>
      </ud-pro-table>
    </a-card>
    <!-- 编辑弹窗 -->
    <batteryEdit v-model:visible="showEdit" :data="current" @done="reload" />
    <!-- 详情弹窗 -->
    <brand-detail v-model:visible="showInfo" :data="current || {}" />
  </div>
</template>

<script>
import * as batteryApi from '@/api/rnt/battery'
import batteryEdit from './index-edit'
import brandDetail from './index-detail.vue'
import * as batteryModelApi from '@/api/rnt/batteryModel'
import { createVNode } from 'vue'
import {
  ExclamationCircleOutlined
} from '@ant-design/icons-vue'
export default {
  name: 'batteryList',
  components: {
    batteryEdit,
    brandDetail
  },
  data() {
    return {
      name: 'batterytList',
      // 表格列配置
      columns: [
        {
          key: 'index',
          width: 48,
          customRender: ({ index }) => this.$refs.table.tableIndex + index
        },
        {
          title: '电池编号',
          dataIndex: 'batteryCode',
          sorter: true
        },
        {
          title: '设备号(IMEI)',
          dataIndex: 'batteryImei',
          sorter: true
        },
        {
          title: '型号',
          dataIndex: 'model',
          sorter: true
        },
        {
          title: '电池协议',
          dataIndex: 'protocolType',
          sorter: true
        },
        {
          title: '运营商名称',
          dataIndex: 'operatorName',
          sorter: true
        },
        {
          title: '所属租赁点名称',
          dataIndex: 'pointName',
          sorter: true
          // slots: { customRender: 'pointName' }
        },
        {
          title: '仓库名称',
          dataIndex: 'warehouseName',
          sorter: true
        },
        // 启用
        {
          title: '启用',
          dataIndex: 'enable',
          sorter: true,
          width: 90,
          align: 'center',
          slots: { customRender: 'enable' }
        },
        {
          title: '操作',
          key: 'action',
          width: 200,
          align: 'center',
          slots: { customRender: 'action' }
        }
      ],
      brandList: [],
      // 表格搜索条件
      where: {},
      // 表格选中数据
      selection: [],
      // 是否显示编辑弹窗
      showEdit: false,
      // 是否显示详情弹窗
      showInfo: false,
      // 当前编辑数据
      current: null,
      // 编辑表单提交状态
      editLoading: false
    }
  },
  mounted() {
    // this.queryBatteryBrand()
    this.queryBatteryBrand()
  },
  methods: {
    // 查询所有电池品牌
    queryBatteryBrand() {
      batteryModelApi
        .brand()
        .then((res) => {
          if (res.code === 0) {
            this.brandList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    // 运营商改变获取对应品牌列表
    changeOperator(value) {
      this.form.brandName = ''
      batteryModelApi
        .brand({ operatorId: value })
        .then((res) => {
          if (res.code === 0) {
            this.brandList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    /* 修改用户状态 */
    editEnabled(checked, row) {
      const data = {
        enable: checked ? 0 : 1,
        batteryId: row.batteryId
      }
      batteryApi
        .enable(data)
        .then((res) => {
          if (res.code === 0) {
            row.enable = checked ? 0 : 1
            this.$message.success(res.msg)
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    /* 详情 */
    openDetail(row) {
      this.current = row
      this.showInfo = true
    },
    datasource(option, callback) {
      batteryApi
        .page({
          ...this.where,
          page: option.page,
          limit: option.limit
        })
        .then((res) => {
          if (res.code === 0) {
            callback(res.data, res.count)
          } else {
            callback(res.msg)
          }
        })
        .catch((e) => {
          callback(e.message)
        })
    },
    /* 刷新表格 */
    reload() {
      this.selection = []
      this.$refs.table.reload({ page: 1 })
    },
    /* 重置搜索 */
    reset() {
      this.where = {}
      this.$nextTick(() => {
        this.reload()
      })
    },
    /* 删除单个 */
    remove(row) {
      const hide = this.$message.loading('请求中..', 0)
      batteryApi
        .deleteById(row.batteryId)
        .then((res) => {
          hide()
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.reload()
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          hide()
          this.$message.error(e.message)
        })
    },
    /* 批量删除 */
    removeBatch() {
      if (!this.selection.length) {
        this.$message.error('请至少选择一条数据')
        return
      }
      this.$confirm({
        title: '提示',
        content: '确定要删除选中的项目吗?',
        icon: createVNode(ExclamationCircleOutlined),
        maskClosable: true,
        onOk: () => {
          const hide = this.$message.loading('请求中..', 0)
          const data = { data: this.selection.map((d) => d.batteryId) }
          batteryApi
            .deleteBatch(data)
            .then((res) => {
              hide()
              if (res.code === 0) {
                this.$message.success(res.msg)
                this.reload()
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((e) => {
              hide()
              this.$message.error(e.message)
            })
        }
      })
    },
    /* 打开编辑弹窗 */
    openEdit(row) {
      this.current = row
      this.showEdit = true
    }
  }
}
</script>

<style scoped>
</style>
